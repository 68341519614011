import { Box } from '@material-ui/core';
import { TypeToast, useToast } from 'admin-portal-shared-services';
import { formatMessage } from 'i18n/formatters';
import { ReactNode } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

type CopyWithToastProps = {
  children: ReactNode;
  text: string;
  toastText?: string;
};

function CopyWithToast({ children, text, toastText }: CopyWithToastProps): JSX.Element {
  const toastService = useToast();

  const openCopyToast = () => {
    toastService.notify({
      message: toastText || formatMessage({ id: 'MonitoringPage.COPIED' }),
      type: TypeToast.INFO,
    });
  };

  return (
    <CopyToClipboard text={text} onCopy={openCopyToast}>
      <Box
        data-testid="wrapper"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {children}
      </Box>
    </CopyToClipboard>
  );
}

export default CopyWithToast;
