import {
  IMonitoringDetailsRecord,
  IMonitoringRecords,
  IStatusResponse,
} from 'interfaces/IMonitoringResponse';
import {
  CustomStatus,
  SourceSystem,
  Status,
  Steps,
} from 'pages/MonitoringDetails/v2/consts/chunks';

const internalSourceSystems = ['RELAY', 'ETL'];

export interface IExtendedMonitoringDetailsRecord extends IMonitoringDetailsRecord {
  status: IExtendedStatusResponse;
}

export interface IStep {
  name: string;
  status: string;
  amount: number;
  chunks: Record<string, IExtendedMonitoringDetailsRecord[]>;
}

interface IChunksMap {
  totalRecords: number;
  steps: IStep[];
}

interface IExtendedStatusResponse extends IStatusResponse {
  customStatus: string;
}

// sort records by traceID
const sortRecordsByTraceID = (
  records: IExtendedMonitoringDetailsRecord[]
): IExtendedMonitoringDetailsRecord[] => records.sort(sortTraceID);

const sortTraceID = (
  a: IExtendedMonitoringDetailsRecord,
  b: IExtendedMonitoringDetailsRecord
): number => {
  // Extract the parts after "part-"
  const regex = /part-(\d+)-part-(\d+)-of-(\d+)/;

  const aMatch = a.traceID.match(regex);
  const bMatch = b.traceID.match(regex);

  const [, aPart1, aPart2, aOf] = aMatch ? aMatch.map(Number) : [0, 0, 0];
  const [, bPart1, bPart2, bOf] = bMatch ? bMatch.map(Number) : [0, 0, 0];

  // Compare the first set of numbers
  if (aPart1 !== bPart1) {
    return aPart1 - bPart1;
  }

  // If the first set of numbers are the same, compare the second set
  if (aPart2 !== bPart2) {
    return aPart2 - bPart2;
  }

  // If the second set of numbers are the same, compare the third set
  return aOf - bOf;
};

export const mergeRecords = (
  records: IExtendedMonitoringDetailsRecord[]
): IExtendedMonitoringDetailsRecord[] => {
  const mergedRecords: IExtendedMonitoringDetailsRecord[] = [];
  const traceIdMap = new Map<string, IExtendedMonitoringDetailsRecord>();

  records.forEach((record) => {
    // merge records with the same traceID and same sourceSystem
    if (!traceIdMap.has(record.traceID + record.sourceSystem)) {
      traceIdMap.set(record.traceID + record.sourceSystem, record);
      mergedRecords.push(record);
    }
  });

  return mergedRecords;
};

// Short records by status, first failed, then in progress and finally completed
export const sortRecordsByStatus = (
  records: IExtendedMonitoringDetailsRecord[]
): IExtendedMonitoringDetailsRecord[] => {
  const failedRecords = records.filter(
    (record) => record.status.customStatus === CustomStatus.FAILED
  );
  const inProgressRecords = records.filter(
    (record) => record.status.customStatus === CustomStatus.IN_PROGRESS
  );
  const completedRecords = records.filter(
    (record) => record.status.customStatus === CustomStatus.COMPLETED
  );

  return [...failedRecords, ...inProgressRecords, ...completedRecords];
};

export const getSteps = (
  record: IExtendedMonitoringDetailsRecord,
  records: IExtendedMonitoringDetailsRecord[],
  VSFeedbackEnabled: boolean
): string[] => {
  const {
    sourceSystem,
    status: { name: statusName },
  } = record;
  const metadata = record.metadata ? JSON.parse(record.metadata) : null;
  const isSyncSourceSystem =
    sourceSystem === SourceSystem.RELAY || sourceSystem === SourceSystem.ETL;
  const routedToVS =
    metadata?.destinations?.['queue-shared']?.routed ||
    metadata?.destinations?.relay?.routed ||
    metadata?.destinations?.['sync-router']?.routed;
  const routedToMLP = metadata?.destinations?.['queue-mlp']?.routed;

  const handleRelaySourceSystem = () => [Steps.DATA_ENTRY];

  const handleETLSourceSystemWithDestinations = () => {
    const foundRecord = records.find(
      (secondaryRecord) =>
        secondaryRecord.traceID.startsWith(record.traceID) &&
        secondaryRecord.sourceSystem !== SourceSystem.ETL &&
        secondaryRecord.sourceSystem !== SourceSystem.RELAY
    );

    if (!foundRecord) {
      // When is a sync source system, no VS record found and no VSFeedbackEnabled, set the step to DATA_DISTRIBUTION
      // When is a sync source system, no VS record found, VSFeedbackEnabled is enabled, routedToVS and routedToMLP, set the step to DATA_DISTRIBUTION
      if (!VSFeedbackEnabled || (routedToVS && routedToMLP)) {
        return [Steps.DATA_DISTRIBUTION, Steps.ADHERENCE_VALIDATION];
      }

      // When is a sync source system, no VS record found and VSFeedbackEnabled, set the step to CONFIRMATION
      return [Steps.CONFIRMATION, Steps.DATA_DISTRIBUTION, Steps.ADHERENCE_VALIDATION];
    }

    // When is a sync source system, VS record is found and VSFeedbackEnabled, set the step to DATA_DISTRIBUTION
    return [Steps.DATA_DISTRIBUTION, Steps.ADHERENCE_VALIDATION];
  };

  const handleETLSourceSystemWithoutDestinations = () => [Steps.ADHERENCE_VALIDATION];

  const handleVSRecords = () =>
    !VSFeedbackEnabled ? [Steps.DATA_DISTRIBUTION] : [Steps.CONFIRMATION];

  if (isSyncSourceSystem) {
    if (record.sourceSystem === SourceSystem.RELAY) {
      return handleRelaySourceSystem();
    }

    if (metadata?.destinations) {
      if (routedToVS && statusName === Status.SUCCESS) {
        return handleETLSourceSystemWithDestinations();
      }

      return [Steps.DATA_DISTRIBUTION, Steps.ADHERENCE_VALIDATION];
    }

    return handleETLSourceSystemWithoutDestinations();
  }

  return handleVSRecords();
};

export const addChunksToSteps = (
  records: IExtendedMonitoringDetailsRecord[],
  VSFeedbackEnabled: boolean
): Record<string, IExtendedMonitoringDetailsRecord[]> => {
  const categorizedRecords: Record<string, IExtendedMonitoringDetailsRecord[]> = {
    DATA_ENTRY: [],
    ADHERENCE_VALIDATION: [],
    DATA_DISTRIBUTION: [],
    CONFIRMATION: [],
  };

  const addRecordToSuccessSteps = (record: IExtendedMonitoringDetailsRecord, steps: string[]) => {
    steps.forEach((step) => {
      // find matching records
      const foundRecord = records.find(
        (secondaryRecord) =>
          secondaryRecord.traceID.startsWith(record.traceID) &&
          secondaryRecord.sourceSystem !== SourceSystem.ETL
      );

      // When step is ADHERENCE_VALIDATION or DATA_DISTRIBUTION set the status to IN_PROGRESS
      const status = getChunkWithCustomStatus(record, records, step, VSFeedbackEnabled, true);

      // when step is CONFIRMATION and the record source system is ETL set the status to IN_PROGRESS
      if (step === Steps.CONFIRMATION && record.sourceSystem === SourceSystem.ETL && !foundRecord) {
        status.status.customStatus = CustomStatus.IN_PROGRESS;
      }

      // Handle when step is CONFIRMATION and the record source system is ETL and has VS record
      if (step === Steps.CONFIRMATION && record.sourceSystem === SourceSystem.ETL && foundRecord) {
        return;
      }

      // Handle when step is DATA_DISTRIBUTION and the record source system is ETL, not has feedback layer but also has a VS record
      if (
        step === Steps.DATA_DISTRIBUTION &&
        record.sourceSystem === SourceSystem.ETL &&
        foundRecord &&
        !VSFeedbackEnabled
      ) {
        return;
      }
      categorizedRecords[step].push({
        ...record,
        status: { name: Status.SUCCESS, message: '', customStatus: status.status.customStatus },
      });
    });
  };

  records.forEach((record) => {
    const steps = getSteps(record, records, VSFeedbackEnabled);

    // add record to failed step
    if (
      record.status.name === Status.FAILURE ||
      record.status.customStatus === CustomStatus.IN_PROGRESS
    ) {
      const failedStep = steps[0];
      const successSteps = steps.slice(1);

      addRecordToSuccessSteps(record, successSteps);

      if (failedStep) {
        categorizedRecords[failedStep].push(record);
      }
    }

    if (
      record.status.name === Status.SUCCESS &&
      record.status.customStatus === CustomStatus.COMPLETED
    ) {
      addRecordToSuccessSteps(record, steps);
    }
  });

  // Handle MLP only records
  const routedToMLP = records.some((record) => {
    const metadata = record.metadata ? JSON.parse(record.metadata) : null;
    return metadata?.destinations?.['queue-mlp']?.routed;
  });

  const foundVSRecords = records.some(
    (record) =>
      record.sourceSystem !== SourceSystem.ETL &&
      record.sourceSystem !== SourceSystem.RELAY &&
      record.sourceSystem !== SourceSystem.ADAPTER
  );

  // case VSFeedbackEnabled is false or is a MLP only record, remove CONFIRMATION step
  if (!VSFeedbackEnabled || (routedToMLP && !foundVSRecords)) {
    delete categorizedRecords.CONFIRMATION;
  }

  return categorizedRecords;
};

export const categorizeStepsChunksByStatus = (
  steps: Record<string, IExtendedMonitoringDetailsRecord[]>,
  VSFeedbackEnabled?: boolean
): IStep[] => {
  const categorizedChunks: IStep[] = [];
  const confirmationStep = steps[Steps.CONFIRMATION];
  const dataDistributionStep = steps[Steps.DATA_DISTRIBUTION];

  const confirmationStepOnlyCompletedRecords =
    confirmationStep?.length &&
    confirmationStep?.every((record) => record.status.customStatus === CustomStatus.COMPLETED);

  const dataDistributionStepOnlyCompletedRecords =
    dataDistributionStep?.length &&
    dataDistributionStep?.every((record) => record.status.customStatus === CustomStatus.COMPLETED);

  const completedIngestion =
    (VSFeedbackEnabled && confirmationStepOnlyCompletedRecords) ||
    (!VSFeedbackEnabled && dataDistributionStepOnlyCompletedRecords);

  Object.entries(steps).forEach(([step, records], index) => {
    const nextStep = categorizedChunks[index + 1];
    const previousStep = categorizedChunks[index - 1];

    const statusChunks = records.reduce((acc, record) => {
      const {
        status: { customStatus: statusName },
      } = record;

      if (!statusName) {
        return acc;
      }

      acc[statusName] ??= [];
      acc[statusName].push(record);
      return acc;
    }, {} as Record<string, IExtendedMonitoringDetailsRecord[]>);

    // define step status based on chunks status
    let stepStatusName = CustomStatus.COMPLETED;

    if (statusChunks[CustomStatus.IN_PROGRESS]?.length) {
      stepStatusName = CustomStatus.IN_PROGRESS;
    }

    if (statusChunks[CustomStatus.FAILED]?.length) {
      stepStatusName = CustomStatus.FAILED;
    }

    // if step has no records, set status to COMPLETED
    if (!records.length) {
      stepStatusName = CustomStatus.COMPLETED;
    }

    // if step is the last step and has no records, set status to IN_PROGRESS
    if (!records.length && !nextStep) {
      stepStatusName = CustomStatus.IN_PROGRESS;
    }

    // if step is the last step and has records with IN_PROGRESS status, set status to IN_PROGRESS
    if (!nextStep && statusChunks[CustomStatus.IN_PROGRESS]?.length) {
      stepStatusName = CustomStatus.IN_PROGRESS;
    }

    // if has an previous step and the previous step is not COMPLETED, set status to IN_PROGRESS
    if (previousStep && previousStep.status !== CustomStatus.COMPLETED) {
      stepStatusName = CustomStatus.IN_PROGRESS;
    }

    // if has an previous step and the previous step is not COMPLETED and has records with FAILED status, set status to FAILED
    if (previousStep && previousStep.status !== CustomStatus.COMPLETED) {
      if (statusChunks[CustomStatus.FAILED]?.length) {
        stepStatusName = CustomStatus.FAILED;
      }
    }

    // if step is DATA_DISTRIBUTION or ADHERENCE_VALIDATION, has records with IN_PROGRESS status and confirmation step exists with completed records, set the status to COMPLETED
    if (
      (step === Steps.DATA_DISTRIBUTION || step === Steps.ADHERENCE_VALIDATION) &&
      statusChunks[CustomStatus.IN_PROGRESS]?.length &&
      completedIngestion
    ) {
      stepStatusName = CustomStatus.COMPLETED;
    }

    categorizedChunks[index] = {
      name: step,
      status: stepStatusName,
      chunks: statusChunks,
      amount: records.length,
    };
  });

  return categorizedChunks;
};

// define chunk custom status based on metadata
export const getChunkWithCustomStatus = (
  record: IMonitoringDetailsRecord,
  records: IMonitoringDetailsRecord[],
  step?: string,
  VSFeedbackEnabled?: boolean,
  isSuccessStep?: boolean
): IExtendedMonitoringDetailsRecord => {
  const metadata = record.metadata ? JSON.parse(record.metadata) : null;
  const {
    sourceSystem,
    status: { name: statusName },
  } = record;

  const isSyncSourceSystem =
    sourceSystem === SourceSystem.RELAY ||
    sourceSystem === SourceSystem.ETL ||
    sourceSystem === SourceSystem.ADAPTER;

  // Create a new status object to ensure it's extensible
  const newStatus = { ...record.status, customStatus: '' };

  if (statusName === Status.FAILURE && !isSuccessStep) {
    newStatus.customStatus = CustomStatus.FAILED;
  }

  if (statusName === Status.SUCCESS) {
    newStatus.customStatus = CustomStatus.COMPLETED;
  }

  // Define IN_PROGRESS scenarios
  if (isSyncSourceSystem) {
    const foundRecord = records.find(
      (secondaryRecord) =>
        secondaryRecord.traceID.startsWith(record.traceID) &&
        secondaryRecord.sourceSystem !== SourceSystem.ETL
    );

    const routedToMLP = metadata?.destinations?.['queue-mlp']?.routed;

    if (step) {
      // When is isSyncSourceSystem, step is CONFIRMATION, no VS record found, VSFeedbackEnabled is true and the status is SUCCESS, set the status to IN_PROGRESS
      if (
        step === Steps.CONFIRMATION &&
        statusName === Status.SUCCESS &&
        !foundRecord &&
        isSyncSourceSystem &&
        VSFeedbackEnabled
      ) {
        newStatus.customStatus = CustomStatus.IN_PROGRESS;
      }
    }

    // When is record is routedToMLP and with success status, set the status to COMPLETED
    if (routedToMLP && statusName === Status.SUCCESS) {
      newStatus.customStatus = CustomStatus.COMPLETED;
    }

    // When is record is routedToMLP, with success status and no VS record found, but the VSFeedbackEnabled is true, set the status to IN_PROGRESS
    if (routedToMLP && statusName === Status.SUCCESS && VSFeedbackEnabled && !foundRecord) {
      newStatus.customStatus = CustomStatus.IN_PROGRESS;
    }
  }

  // Return a new record object with the updated status
  return { ...record, status: { ...record.status, customStatus: newStatus.customStatus } };
};

export const getStepChunks = (
  records: IMonitoringDetailsRecord[],
  recordsRelay: IMonitoringRecords[] | undefined,
  VSFeedbackEnabled: boolean
): IChunksMap => {
  // map recordsDetailsTrace to IMonitoringDetailsRecord
  const recordsDetailsTraceMap: IMonitoringDetailsRecord[] = recordsRelay
    ? recordsRelay?.map((record) => ({
        id: record.id,
        createdAt: record.createdAt as unknown as string,
        status: record.status,
        traceID: record.traceId ?? '',
        sourceSystem: record.sourceSystem ?? '',
        metadata: record.metadata ? JSON.stringify(record.metadata) : '',
      }))
    : [];

  // contact records and recordsDetailsTrace
  const allRecords = [...records, ...recordsDetailsTraceMap];

  const chunksWithCustomStatus = allRecords.map((record) =>
    getChunkWithCustomStatus(record, allRecords)
  );
  const sortedRecords = sortRecordsByTraceID(chunksWithCustomStatus);
  const mergedRecords = mergeRecords(sortedRecords);
  const sortedRecordsByStatus = sortRecordsByStatus(mergedRecords);
  const categorizedChunks = addChunksToSteps(sortedRecordsByStatus, VSFeedbackEnabled);
  const categorizedChunksByStatus = categorizeStepsChunksByStatus(
    categorizedChunks,
    VSFeedbackEnabled
  );

  return { totalRecords: records.length, steps: categorizedChunksByStatus };
};

export const isRecordExactMatch = (record: IMonitoringRecords): boolean => {
  const { sourceSystem, traceId } = record;

  return (
    (sourceSystem && internalSourceSystems.includes(sourceSystem)) ||
    countOccurrences(traceId ?? '', 'part') <= 1
  );
};

const countOccurrences = (str: string, value: string): number =>
  str.split('-').filter((item) => item === value).length;
