import { styled } from 'themes';

export const FeedbackModalButtonsContainer = styled('div', {
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'right',
  gap: '$4',
  fontSize: '$large',
  a: {
    fontWeight: '500 !important',
  },
});
