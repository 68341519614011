import { styled } from 'themes';

export const FeedbackContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  margin: '0 auto',
  paddingTop: '$6 ',
  gap: '$6',
  fontFamily: '$primary',
  fontSize: '$base',
  fontWeight: '600',
  '@tablet': {
    maxWidth: '700px',
  },
  '@desktopSmall': {
    maxWidth: '720px',
  },
  '@desktopXlarge': {
    maxWidth: '1280px',
  },
});

export const FeedbackAnswerButtonsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '$6',
  fontSize: '$large',
  a: {
    fontWeight: '500 !important',
  },
});
