import {
  appHeaderConfig as appConfig,
  AppHeaderConfigPayload,
  useAppHeader,
  useUserMetadata,
} from 'admin-portal-shared-services';
import { ElementLocationType, ElementType, SpecificApp } from 'analytics';
import { SegmentEvents } from 'consts/segment';
import UserInfoContext from 'context/UserInfoContext';
import { useSegmentEvent } from 'hooks/useSegmentAnalytics';
import { formatMessage } from 'i18n/formatters';
import { useContext, useEffect, useLayoutEffect } from 'react';
import { setUserLocalData } from 'services/userLocalData/UserLocalDataService';

interface IUseAppHeaderService extends AppHeaderConfigPayload {
  hidePageTitle?: boolean;
  onCountryChange?: (country: string) => void;
  onVendorChange?: (vendor: string) => void;
}

const originalOnCountryChange = appConfig.onChangeSelectedCountry;
const originalOnVendorChange = appConfig.onChangeSelectedVendor;

export const useAppHeaderService = ({
  hidePageTitle,
  onCountryChange,
  onVendorChange,
  ...config
}: IUseAppHeaderService = {}) => {
  const [appHeaderConfig, setAppHeaderConfig] = useAppHeader();
  const { setUserInfoContext } = useContext(UserInfoContext);
  const { data: metadata, isLoading: isMetadataLoading } = useUserMetadata();
  const { selectedCountry, selectedVendor, loadingVendors, countryOptions } = appHeaderConfig;
  const event = useSegmentEvent();

  const onCountryValueChange = (payload: string) => {
    originalOnCountryChange(payload);
    if (onCountryChange) onCountryChange(payload);
    setTimeout(() => {
      event(SegmentEvents.ELEMENT_CLICKED, {
        element_location_type: ElementLocationType.Page,
        element_location_name: 'Data update report Country and Vendor selects',
        element_label: 'Country',
        element_name: 'CountryDropdown',
        element_type: ElementType.Card,
        specific_app: SpecificApp.Monitoring,
      });
    }, 1000);
  };
  const onVendorValueChange = (payload: string) => {
    originalOnVendorChange(payload);
    if (onVendorChange) onVendorChange(payload);
    setTimeout(() => {
      event(SegmentEvents.ELEMENT_CLICKED, {
        element_location_type: ElementLocationType.Page,
        element_location_name: 'Data update report Country and Vendor selects',
        element_label: 'Vendor',
        element_name: 'VendorDropdown',
        element_type: ElementType.Card,
        specific_app: SpecificApp.Monitoring,
      });
    }, 1000);
  };

  appConfig.onChangeSelectedCountry = onCountryValueChange;
  appConfig.onChangeSelectedVendor = onVendorValueChange;

  useEffect(() => {
    setAppHeaderConfig({
      pageTitle: formatMessage({ id: 'WelcomePage.TITLE' }),
      countrySelect: true,
      vendorSelect: true,
      ...config,
    });
  }, [setAppHeaderConfig]);

  useLayoutEffect(() => {
    const title = document.querySelector('header#mfe-content-header') as HTMLElement;
    if (hidePageTitle && title) {
      title.classList.add('hideTitle');
    }
    return () => {
      if (title) title.classList.remove('hideTitle');
    };
  }, []);

  useEffect(() => {
    if (countryOptions.length) {
      setUserLocalData({ countriesAvailable: countryOptions });
    }
  }, [countryOptions]);

  useEffect(() => {
    if (selectedCountry && selectedVendor && !loadingVendors && !isMetadataLoading) {
      setUserInfoContext({
        selectedCountry,
        selectedVendor,
      });

      const vendors = metadata?.vendors || [];
      const vendor = vendors.find((vendorOption) => vendorOption.id === selectedVendor) as any;
      setUserLocalData({
        selectedCountry,
        selectedVendor: {
          id: vendor?.id ?? '',
          name: vendor?.displayName ?? '',
          serviceModel: vendor?.serviceModel ?? '',
          country: vendor?.country ?? '',
          isManufacturer: vendor?.isManufacturer ?? false,
          tier: {
            name: vendor?.tier?.name ?? '',
            id: vendor?.id ?? '',
          },
        },
        id: metadata?.userId,
        authorization: metadata?.authorization,
      });
    }
  }, [selectedCountry, selectedVendor, loadingVendors, isMetadataLoading]);

  return { appHeaderConfig, setAppHeaderConfig };
};
