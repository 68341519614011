import { styled } from 'themes';

export const NotFoundStateContainer = styled('div', {
  display: 'flex',
  maxWidth: '400px',
  height: '80vh',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  marginRight: 'auto',
  marginLeft: 'auto',
  padding: '2rem',
  textAlign: 'center',
});
