import { Divider, TextLink, Toast } from '@hexa-ui/components';
import { Box } from '@material-ui/core';
import { useDecision } from '@optimizely/react-sdk';
import { SegmentEvents } from 'consts/segment';
import { useSegmentEvent } from 'hooks/useSegmentAnalytics';
import { formatMessage } from 'i18n/formatters';
import { ISegmentProps } from 'interfaces/ISegment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FeedbackAnswerButtonsContainer, FeedbackContainer } from './Feedback.styles';
import { FeedbackModal } from './Modal/FeedbackModal';

interface IFeedback {
  id: string;
  divider: boolean;
  segmentProps?: Partial<ISegmentProps>;
}

export function Feedback({ id, segmentProps, divider = true }: IFeedback): JSX.Element | null {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [shouldDisplay, setShouldDisplay] = useState<boolean>(true);
  const [feedbackMessage, setFeedbackMessage] = useState<string>('');
  const [feedbackType, setFeedbackType] = useState<string>('');
  const [feedbackIsSent, setFeedbackIsSent] = useState<boolean>(false);

  const feedback = useMemo(() => localStorage.getItem(`feedback-${id}`), [id]);
  const event = useSegmentEvent();
  const feedbackEnabled = useDecision('bees-sync-mfe-feedback')[0].enabled;

  const shouldDisplayFeedback = useCallback(
    () => !feedback && feedbackEnabled,
    [feedback, feedbackEnabled]
  );

  const registerFeedback = useCallback(() => {
    event(SegmentEvents.ELEMENT_CLICKED, {
      content: feedbackMessage,
      user_feedback_root_question_answer: feedbackType,
      ...segmentProps,
    });
    localStorage.setItem(`feedback-${id}`, 'true');
    setFeedbackIsSent(true);
  }, [id, feedbackMessage, feedbackType, segmentProps]);

  const openModal = useCallback((type: string) => {
    setIsOpenModal(true);
    setFeedbackType(type);
  }, []);

  useEffect(() => {
    setShouldDisplay(shouldDisplayFeedback());
  }, [shouldDisplayFeedback]);

  return shouldDisplay ? (
    <>
      {divider && (
        <Box style={{ marginTop: '16px' }}>
          <Divider data-testid="divider" />
        </Box>
      )}
      <FeedbackContainer data-testid="feedback">
        {!feedbackIsSent ? (
          <>
            <span>{formatMessage({ id: 'Feedback.QUESTION' })}</span>
            <FeedbackAnswerButtonsContainer>
              <TextLink hasUnderline={false} onClick={() => openModal('yes')}>
                {formatMessage({ id: 'Feedback.YES' })}
              </TextLink>

              <TextLink hasUnderline={false} onClick={() => openModal('no')}>
                {formatMessage({ id: 'Feedback.NO' })}
              </TextLink>
            </FeedbackAnswerButtonsContainer>
            <FeedbackModal
              isOpen={isOpenModal}
              onClose={() => setIsOpenModal(false)}
              onSubmit={() => {
                registerFeedback();
                setIsOpenModal(false);
              }}
              setMessage={setFeedbackMessage}
            />
          </>
        ) : (
          <span>{formatMessage({ id: 'Feedback.SUCCESS' })}</span>
        )}
      </FeedbackContainer>

      <Toast.Provider duration={3000} swipeDirection="right">
        <Toast.Root
          message={formatMessage({ id: 'Feedback.TOAST' })}
          open={feedbackIsSent}
          position="top-right"
          type="success"
        />
      </Toast.Provider>
    </>
  ) : null;
}
