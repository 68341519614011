import { Button, Dialog, Heading, Paragraph, TextArea } from '@hexa-ui/components';
import { formatMessage } from 'i18n/formatters';
import { Dispatch, SetStateAction } from 'react';
import { FeedbackModalButtonsContainer } from './FeedbackModal.styles';

interface FeedbackModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  setMessage: Dispatch<SetStateAction<string>>;
}

export function FeedbackModal({
  isOpen,
  onClose,
  onSubmit,
  setMessage,
}: FeedbackModalProps): JSX.Element {
  return (
    <Dialog.Root
      open={isOpen}
      onClose={onClose}
      actions={
        <FeedbackModalButtonsContainer>
          <Dialog.Close>
            <Button onClick={onClose} size="medium" variant="secondary">
              {formatMessage({ id: 'FeedbackModal.CANCEL' })}
            </Button>
          </Dialog.Close>
          <Button onClick={onSubmit} size="medium" variant="primary">
            {formatMessage({ id: 'FeedbackModal.CONFIRM' })}
          </Button>
        </FeedbackModalButtonsContainer>
      }
      title={<Heading size="H2">{formatMessage({ id: 'FeedbackModal.TITLE' })}</Heading>}
    >
      <div style={{ marginBottom: '24px' }}>
        <Paragraph>{formatMessage({ id: 'FeedbackModal.SUBTITLE_ERROR_CODE' })}</Paragraph>
      </div>
      <TextArea
        width="100%"
        id="text-area-not-required"
        label={formatMessage({ id: 'FeedbackModal.TEXT_AREA_LABEL' })}
        required={false}
        placeholder={formatMessage({ id: 'FeedbackModal.TEXT_AREA_PLACEHOLDER' })}
        onChange={(e) => setMessage(e.currentTarget.value)}
      />
    </Dialog.Root>
  );
}
