import { parse } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';

// format the difference between two dates in days and minutes
export const formatTimeDifference = (date1: string, date2: string): string => {
  // convert the date strings to date objects
  const date1Obj = new Date(date1);
  const date2Obj = new Date(date2);

  // calculate the difference between the two dates
  const diff = Math.abs(date1Obj.getTime() - date2Obj.getTime());

  const minutes = Math.floor(diff / (1000 * 60)) % 60;
  const hours = Math.floor(diff / (1000 * 60 * 60)) % 24;
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));

  let result = '';
  if (days > 0) {
    result += `${days} day${days > 1 ? 's' : ''}`;
  }
  if (hours > 0) {
    if (result) result += ' and ';
    result += `${hours} hour${hours > 1 ? 's' : ''}`;
  }
  if (minutes > 0) {
    if (result) result += ' and ';
    result += `${minutes} minute${minutes > 1 ? 's' : ''}`;
  }
  return result || '0 minutes';
};

export const convert24ToAMPM = (time: Date) => {
  const hours = time.getHours().toString();
  const hour = hours.length === 1 ? `0${hours}` : hours;
  const minutes =
    time.getMinutes().toString().length === 1 ? `0${time.getMinutes()}` : time.getMinutes();
  const seconds =
    time.getSeconds().toString().length === 1 ? `0${time.getSeconds()}` : time.getSeconds();

  return {
    time: `${hour}:${minutes}:${seconds}`,
    period: parseInt(hours, 10) >= 12 ? 'PM' : 'AM',
  };
};

export const formatDate = (date: string, currentFormat: string, newFormat: string): string => {
  const utcDate = parse(date, currentFormat, new Date());
  const zonedDate = utcToZonedTime(utcDate, 'UTC');
  return format(zonedDate, newFormat, { timeZone: 'UTC' });
};

export const getFormattedDate = (date: Date): string => {
  const isoString = date.toISOString();
  return isoString.split('.')[0];
};

export const getHoursFromDate = (
  hours: number,
  date: string,
  currentFormat: string,
  isBefore: boolean
): string => {
  const currentDate = parse(date, currentFormat, new Date());
  const newDate = new Date(currentDate);

  newDate.setHours(newDate.getHours() + (isBefore ? -hours : hours));

  const newTimeTimezone = new Date(newDate.getTime() - newDate.getTimezoneOffset() * 60000);

  // convert the newDate from ISO to the newFormat with correct timezone
  return getFormattedDate(newTimeTimezone);
};
