import { Heading, Paragraph } from '@hexa-ui/components';
import { EmptyState as EmptyStateIllustration } from '@hexa-ui/illustrations';
import { Box } from '@material-ui/core';
import { formatMessage } from 'i18n/formatters';
import { useHistory } from 'react-router-dom';
import { MONITORINGPAGE_URL } from 'Router';
import { NotFoundStateContainer } from './NotFound.styles';

function NotFound() {
  const history = useHistory();

  return (
    <NotFoundStateContainer>
      <EmptyStateIllustration.NoDataYet width="128px" />
      <Heading size="H3" style={{ marginTop: '1rem', textAlign: 'center' }}>
        {formatMessage({ id: 'MonitoringDetailsPage.NOT_FOUND_TITLE' })}
      </Heading>
      <Paragraph style={{ marginTop: '0.5rem', textAlign: 'center' }}>
        {formatMessage({ id: 'MonitoringDetailsPage.NOT_FOUND_SUBTITLE' })}
      </Paragraph>
      <Box
        className="button-updated"
        style={{ marginTop: '1.5rem', cursor: 'pointer' }}
        onClick={() => history.push(MONITORINGPAGE_URL)}
      >
        <Paragraph weight="medium" size="basis">
          {formatMessage({ id: 'MonitoringDetailsPage.BACK_TO_MONITORING' })}
        </Paragraph>
      </Box>
    </NotFoundStateContainer>
  );
}

export default NotFound;
