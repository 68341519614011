import { Copy } from '@hexa-ui/icons';
import { styled } from 'themes';

export const Circle = styled('div', {
  height: '40px',
  width: '40px',
  borderRadius: '512px',
  backgroundColor: '#e7e7e7',
  display: 'flex',
  alignSelf: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '10px',
  cursor: 'pointer',
});

export const WrapperTitle = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: '25px',
});

export const TextPrimary = styled('span', {
  fontSize: '14px',
  fontWeight: '600',
  color: '#141414',
});

export const Row = styled('div', {
  display: 'flex',
  flexDirection: 'row',
});

export const CopyContainer = styled('div', {
  display: 'flex',
  gap: '8px',
  lineHeight: '24px',
});

export const CopyIcon = styled(Copy, {
  color: '#006EDB',
  cursor: 'pointer',
});
