import { BaseQueryArg, BaseQueryFn } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { IMonitoring } from 'interfaces/IMonitoring';
import { IMonitoringDetailsRecord, IMonitoringRecords } from 'interfaces/IMonitoringResponse';
import { convertToMonitoringFormat } from 'pages/Monitoring/components/v1/Monitoring/utils/monitoringAdapter';

const MONITORING_URL = '/feedback-service/v1/';

export interface IMonitoringParams {
  country: string;
  vendor: string;
  page?: string;
  ['start-date']?: string;
  ['end-date']?: string;
  ['parent-trace-id']?: string;
  ['trace-id']?: string;
  version?: string;
  entity?: string;
  status?: string;
  operation?: string;
  id?: string;
  useContainsForParentTraceId?: string;
  sortDateAscending?: boolean;
}

export interface MonitoringResponse {
  apiResponse: Array<IMonitoring>;
  pagination: {
    currentPage: number;
    elementsInPage: number;
    totalElementsPage: number;
    totalPage: number;
  };
}

export interface IMonitoringTraceIdStatusResponse {
  traceId: string;
  status: string;
  parentTraceId: string;
  entityName: string;
  entityVersion: string;
  entityOperation: string;
  createdAt: string;
  updatedAt: string;
  failedChunks: string;
}

export interface MonitoringDetailsResponse {
  apiResponse: IMonitoringDetailsRecord[];
  pagination: {
    currentPage: number;
    elementsInPage: number;
    totalElementsPage: number;
    totalPage: number;
  };
}

export const getStatusQuery = (params: IMonitoringParams): BaseQueryArg<BaseQueryFn> => ({
  url: `${MONITORING_URL}status`,
  params,
});

export const getTraceIdStatusQuery = (params: IMonitoringParams): BaseQueryArg<BaseQueryFn> => ({
  url: `${MONITORING_URL}status/trace-id`,
  params,
});

export const getSummaryQuery = (params: IMonitoringParams): BaseQueryArg<BaseQueryFn> => ({
  url: `${MONITORING_URL}status/summary`,
  params,
});

export const getSummaryDetailsQuery = (params: IMonitoringParams): BaseQueryArg<BaseQueryFn> => ({
  url: `${MONITORING_URL}records/summary`,
  params,
});

export const getRecordDetailsQuery = (params: IMonitoringParams): BaseQueryArg<BaseQueryFn> => ({
  url: `${MONITORING_URL}records/parent-trace-id/${params['parent-trace-id']}`,
  params,
});

export const getPayloadDetailsQuery = (params: IMonitoringParams): BaseQueryArg<BaseQueryFn> => ({
  url: `${MONITORING_URL}records/${params.id}`,
  params,
});

export const getStatusTransformResponse = (
  apiResponse: Array<IMonitoringRecords>,
  meta: { [key: string]: string }
): MonitoringResponse => {
  const currentPage = Number(meta['pageable-current-page']);
  const elementsInPage = Number(meta['pageable-elements-in-page']);
  const totalElementsPage = Number(meta['pageable-total-elements']);
  const totalPage = Number(meta['pageable-total-pages']);
  const result = convertToMonitoringFormat(apiResponse || []);
  return {
    apiResponse: result,
    pagination: { currentPage, elementsInPage, totalElementsPage, totalPage },
  };
};

export const getStatusDetailsTransformResponse = (
  apiResponse: IMonitoringDetailsRecord[],
  meta: { [key: string]: string }
): MonitoringDetailsResponse => {
  const currentPage = Number(meta['pageable-current-page']);
  const elementsInPage = Number(meta['pageable-elements-in-page']);
  const totalElementsPage = Number(meta['pageable-total-elements']);
  const totalPage = Number(meta['pageable-total-pages']);
  return {
    apiResponse,
    pagination: { currentPage, elementsInPage, totalElementsPage, totalPage },
  };
};

export const getRecordsFromTraceIdQuery = (
  params: IMonitoringParams
): BaseQueryArg<BaseQueryFn> => ({
  url: `${MONITORING_URL}records/trace-id/${params.id}`,
  params: { country: params.country, vendor: params.vendor },
});
