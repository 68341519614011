import { Card, Heading, Paragraph } from '@hexa-ui/components';
import { Box } from '@material-ui/core';
import { ElementLocationType, ElementType, SpecificApp } from 'analytics';
import { Feedback } from 'components/Feedback/Feedback';
import { DataChunkContext, IDataChunkDetails } from 'context/DataChunkContext';
import { formatMessage } from 'i18n/formatters';
import { useContext } from 'react';
import CopyWithToast from '../CopyWithToast/CopyWithToast';
import { CopyContainer, CopyIcon, TextPrimary } from './CardErrorDetails.styles';

type CardErrorProps = {
  errorMessage?: string;
  feedbackId?: string;
};

type FeedbackSectionProps = {
  feedbackId: string;
  chunkDetails: IDataChunkDetails;
};

function FeedbackSection({ feedbackId, chunkDetails }: FeedbackSectionProps): JSX.Element {
  return (
    <Feedback
      id={feedbackId}
      segmentProps={{
        element_location_type: ElementLocationType.Page,
        element_location_name: 'Error details',
        element_label: 'Was this information helpful?',
        element_name: 'Feedback',
        element_type: ElementType.Button,
        specific_app: SpecificApp.Monitoring,
        status: chunkDetails.status,
        data_chunk_trace_id: chunkDetails.traceId,
        data_chunk_step: chunkDetails.step,
        data_chunk_source_system: chunkDetails.sourceSystem,
        entity: chunkDetails.entity,
        action: chunkDetails.action,
        version: chunkDetails.version,
        data_chunk_error_message: chunkDetails.errorMessage,
        data_chunk_payload_present: !!chunkDetails.payload,
        user_feedback_root_question: 'Was this information helpful?',
        user_feedback_message_error_code: chunkDetails.errorCode,
        user_feedback_message_has_suggestion: false,
      }}
      divider
    />
  );
}

function CardErrorDetails({ errorMessage, feedbackId }: CardErrorProps): JSX.Element {
  const { chunkDetails } = useContext(DataChunkContext);

  return (
    <Card
      data-testid="card-chunk-details"
      border="medium"
      elevated="small"
      style={{ padding: '24px', display: 'flex', flexDirection: 'column', gap: '16px' }}
    >
      <Heading size="H4" style={{ marginBottom: '8px' }}>
        {formatMessage({ id: 'MonitoringPage.ERROR_DETAILS' })}
      </Heading>
      {chunkDetails?.errorCode && (
        <Box data-testid="error-code-container">
          <TextPrimary>{formatMessage({ id: 'MonitoringPage.ERROR_CODE' })}</TextPrimary>
          <Paragraph size="small">{chunkDetails.errorCode}</Paragraph>
        </Box>
      )}
      <Box>
        <TextPrimary>{formatMessage({ id: 'MonitoringPage.ERROR_MESSAGE' })}</TextPrimary>
        <CopyContainer>
          <Paragraph size="small">{errorMessage}</Paragraph>
          <CopyWithToast text={errorMessage || ''}>
            <CopyIcon size="medium" />
          </CopyWithToast>
        </CopyContainer>
        {!!feedbackId && <FeedbackSection feedbackId={feedbackId} chunkDetails={chunkDetails} />}
      </Box>
    </Card>
  );
}
export default CardErrorDetails;
