import { Badge, Card, Paragraph } from '@hexa-ui/components';
import { Checkmark2 } from '@hexa-ui/icons';
import { Box, capitalize } from '@material-ui/core';
import { ElementLocationType, ElementType, PageName, SpecificApp } from 'analytics';
import { SegmentEvents } from 'consts/segment';
import { formatMessage } from 'i18n/formatters';
import { IMonitoringDetailsRecord } from 'interfaces/IMonitoringResponse';
import { SegmentProps } from 'interfaces/SegmentProps';
import { CSSProperties } from 'react';
import { IExtendedMonitoringDetailsRecord } from 'services/monitoring/recordService';
import { CustomStatus, Steps } from '../../consts/chunks';
import ProgressCounter from '../ProgressCounter/ProgressCounter';
import './StepCard.css';

interface IStepCardProps {
  index: string;
  step: string;
  status: string;
  amount: number;
  isLastStep?: boolean;
  onClick?: () => void;
  style?: CSSProperties;
  chunks?: {
    [key: string]: IMonitoringDetailsRecord[];
  };
  segmentProps?: SegmentProps;
}

function StepCard({
  index,
  step,
  status,
  amount,
  chunks,
  isLastStep = false,
  onClick,
  style,
  segmentProps,
}: Readonly<IStepCardProps>) {
  if (!chunks) {
    return null;
  }

  const isCompleted = status === CustomStatus.COMPLETED;

  const showTitle =
    (status === CustomStatus.COMPLETED && isCompleted) ||
    status === CustomStatus.FAILED ||
    (status === CustomStatus.IN_PROGRESS && chunks.FAILED && chunks.FAILED.length !== 0);

  return (
    <Card
      border="medium"
      elevated="minimal"
      onClick={onClick}
      className={isCompleted && step !== Steps.CONFIRMATION && !isLastStep ? 'completed-step' : ''}
      style={{
        padding: '1rem',
        display: 'flex',
        flex: 1,
        cursor: !isCompleted || isLastStep ? 'pointer' : 'not-allowed',
        ...style,
        minHeight: '120px',
      }}
    >
      <Box
        onClick={() => {
          if (!segmentProps) return;
          segmentProps.event(SegmentEvents.ELEMENT_CLICKED, {
            page_name: PageName.BeesSyncMonitoringDataChunkDetails,
            element_location_name: 'Data chunks overview',
            element_location_type: ElementLocationType.Page,
            element_type: ElementType.Button,
            element_label: step,
            element_name: 'UpdateDetails_StepCard',
            specific_app: SpecificApp.Monitoring,
            viewed_step_when_triggered: step,
            viewed_step_situation_when_triggered: status,
            status: segmentProps.status,
            version: segmentProps.version,
            action: segmentProps.action,
            entity: segmentProps.entity,
          });
        }}
      >
        <div className="card-header" data-testid="step-card">
          {chunks.FAILED && chunks.FAILED.length !== 0 && (
            <Badge.Counter counterLabel={chunks.FAILED.length} className="step-badge" />
          )}
          <div className="wrapper-success">
            {isCompleted && <Checkmark2 className="success-icon" size="large" />}
            <Paragraph colortype="error" className={status.toLowerCase()}>
              {showTitle ? capitalize(status.toLowerCase()) : ''}
            </Paragraph>
          </div>
        </div>
        <div className="card-step-title">
          {Number(index) + 1} {formatMessage({ id: `DescriptionsUpdateDetails.${step}` })}
        </div>
        <div className="card-step-description">
          {(step === Steps.DATA_DISTRIBUTION || step === Steps.ADHERENCE_VALIDATION) &&
          chunks[CustomStatus.COMPLETED] ? (
            <ProgressCounter
              chunkKey={CustomStatus.COMPLETED}
              value={chunks[CustomStatus.COMPLETED] as IExtendedMonitoringDetailsRecord[]}
              amount={amount}
              stepStatus={status as CustomStatus}
              stepName={step}
            />
          ) : (
            Object.entries(chunks).map(([chunkKey, value]) => (
              <ProgressCounter
                key={chunkKey}
                chunkKey={chunkKey}
                value={value as IExtendedMonitoringDetailsRecord[]}
                amount={amount}
                stepStatus={status as CustomStatus}
                stepName={step}
              />
            ))
          )}
        </div>
      </Box>
    </Card>
  );
}

export default StepCard;
