// IndexedDB utility functions

const DB_NAME = 'data-ingestion-cache';
const DB_VERSION = 1;
const STORE_NAME = 'payload-details';

// Define a generic type for the payload data
export type PayloadData = {
  data: any;
  [key: string]: any;
};

// Helper function to make data serializable (remove functions, etc.)
export const makeSerializable = (data: any): any => {
  if (!data) return data;

  // Convert to JSON and back to remove non-serializable properties
  try {
    return JSON.parse(JSON.stringify(data));
  } catch (error) {
    console.error('Error making data serializable:', error);
    // If serialization fails, try to extract just the data property
    if (data.data) {
      return { data: makeSerializable(data.data) };
    }
    return null;
  }
};

// Helper function to validate retrieved data
const isValidPayloadData = (data: any): boolean =>
  data && typeof data === 'object' && data.data !== undefined;

// Initialize the database
export const initDB = (): Promise<IDBDatabase> =>
  new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, DB_VERSION);

    request.onerror = (event) => {
      console.error('IndexedDB error:', event);
      reject(new Error('Error opening IndexedDB'));
    };

    request.onsuccess = (event) => {
      const db = (event.target as IDBOpenDBRequest).result;
      resolve(db);
    };

    request.onupgradeneeded = (event) => {
      const db = (event.target as IDBOpenDBRequest).result;
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME, { keyPath: 'id' });
      }
    };
  });

// Save payload details to IndexedDB
export const savePayloadDetails = async (id: string, data: PayloadData): Promise<void> => {
  try {
    if (!id || !data) {
      console.warn('Invalid data or ID provided to savePayloadDetails');
      return;
    }

    const db = await initDB();
    await new Promise<void>((resolve, reject) => {
      const transaction = db.transaction([STORE_NAME], 'readwrite');
      const store = transaction.objectStore(STORE_NAME);

      // Make data serializable and add the id
      const serializableData = makeSerializable(data);
      if (!serializableData) {
        console.warn('Could not serialize data for IndexedDB storage');
        resolve();
        return;
      }

      const dataWithId = { ...serializableData, id };

      const request = store.put(dataWithId);

      request.onsuccess = () => {
        console.log(`Successfully saved data for ID: ${id} to IndexedDB`);
        resolve();
      };

      request.onerror = (event) => {
        console.error('Error saving to IndexedDB:', event);
        reject(new Error('Error saving data'));
      };

      transaction.oncomplete = () => {
        db.close();
      };
    });
  } catch (error) {
    console.error('IndexedDB save error:', error);
    throw error;
  }
};

// Get payload details from IndexedDB
export const getPayloadDetails = async (id: string): Promise<PayloadData | null> => {
  try {
    if (!id) {
      console.warn('Invalid ID provided to getPayloadDetails');
      return null;
    }

    const db = await initDB();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction([STORE_NAME], 'readonly');
      const store = transaction.objectStore(STORE_NAME);
      const request = store.get(id);

      request.onsuccess = () => {
        const { result } = request;
        if (isValidPayloadData(result)) {
          console.log(`Successfully retrieved data for ID: ${id} from IndexedDB`);
          resolve(result);
        } else {
          console.warn(`Retrieved invalid data for ID: ${id} from IndexedDB`);
          resolve(null);
        }
      };

      request.onerror = (event) => {
        console.error('Error retrieving from IndexedDB:', event);
        reject(new Error('Error retrieving data'));
      };

      transaction.oncomplete = () => {
        db.close();
      };
    });
  } catch (error) {
    console.error('IndexedDB get error:', error);
    return null;
  }
};
